import React from "react"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import Infograph from "../Infograph/Infograph"
import LoveSql from "../LoveSql/LoveSql"
import Features from "../Features/Features"

const Platform = () => {
  return (
    <div>
      <Breadcrumbs
        title="Platform"
        description="Accessible by all team members, without barriers,"
      />
      <Infograph />
      <LoveSql />
      <Features />
    </div>
  )
}

export default Platform
